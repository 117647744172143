import { useQuery } from "@apollo/client";
//graphql
import { G_SLUGBRAND, G_USERS } from "../graphql/Queries";

//Users
export const QUERY_USERS = () => {
    const { loading, error, data } = useQuery(G_USERS);

    loading && console.log("Loading...");
    error && console.log(`Error! ${error.message}`);
    return data;
}

//Brands
export const QUERY_BRANDSLUG = () => {
    // const sub = useRouter().query.subdomain
    
    const { loading, error, data } = useQuery(G_SLUGBRAND, {
        fetchPolicy: "cache-and-network",
        variables: { slug: 'turia' },       
    });

    loading && console.log("Loading...");
    error && console.log(`Error! ${error.message}`);
    return data && data.brandSlug;
}
