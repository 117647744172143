'use client'
import { Link, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom';
//utils
import { ArrowLeft, ArrowRight, SELogo } from '../../utils/svgs'
import { modules } from '../../utils/linkModules'

import s from "./structure.module.scss";

export const Right = ({
    top,
    logoTop,
    logoHeight,
    borderRadius,
    background,
    children,
    classStyle,
}: any) => {
    const { section, module } = useParams()
    const path = useLocation()?.pathname

    const keys = Object.keys(modules())
    const index = keys.findIndex(key => modules()[key].module === module);

    const getObjectAfterSlug = () => {
        if (index === -1 || index === keys.length - 1) {
            return null;
        } else {
            return modules()[keys[index + 1]];
        }
    }

    const getObjectBeforeSlug = () => {
        if (index <= 0) {
            return null;
        } else {
            return modules()[keys[index - 1]];
        }
    }

    const prev = getObjectBeforeSlug();
    const next = getObjectAfterSlug()
    const download = path.replaceAll('/', '') === 'downloads' && modules()[keys[keys.length - 1]]

    return (
        <div className={`${s.column_bu} ${s.right_bu} ${s[classStyle]}`}>
            {top &&
                <div className={s.top_right}>
                    <div className={s.bu_logo} style={logoHeight && { height: logoHeight }}>
                        {logoTop ?
                            logoTop :
                            <SELogo color={"#253fff"} enredoColor={"#000000"} />}
                    </div>
                    <div className={s.control_pages}>
                        {prev && <div className={s.prev_page}>
                            <Link to={`/section/${prev.section}/${prev.module}`}>
                                <ArrowLeft color={'#000'} />
                                <p>{prev.name}</p>
                            </Link>
                        </div>}
                        {download && <div className={s.prev_page}>
                            <Link to={`/section/${download.section}/${download.module}`}>
                                <ArrowLeft color={'#000'} />
                                <p>{download.name}</p>
                            </Link>
                        </div>}
                        {next ? <div className={s.next_page}>
                            <Link to={`/section/${next.section}/${next.module}`}>
                                <p>{next.name}</p>
                                <ArrowRight color={'#000'} />
                            </Link>
                        </div> : section && <div className={s.next_page}>
                            <Link to={`/downloads/`}>
                                <p>Downloads</p>
                                <ArrowRight color={'#000'} />
                            </Link>
                        </div>}
                        {/* <div className={s.options_page'>
                            <PDF />
                        </div> */}
                    </div>
                </div>
            }

            <div className={s.bu_right_content} style={{
                background: background ? background : 'transparent',
                borderRadius: borderRadius ? `${borderRadius} 0 0 0` : '0px',
            }}
            >
                {children}
            </div >
        </div >
    )
}
