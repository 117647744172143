import { gql } from "@apollo/client";

//User
export const G_USERS = gql`
  query User {
    users {
      id
      name
    }
  }
`;

//Brand
export const G_SLUGBRAND = gql`
  query BrandSlug($slug: String!) {
    brandSlug(slug: $slug) {
      background {
        url
      }
    }
  }
`;
