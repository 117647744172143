// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download_containers__6EKg9 .download_inner__XpXGf {
  height: 100%;
}
.download_containers__6EKg9 .download_inner__XpXGf > * {
  height: 100%;
  min-height: calc(100vh - 110px);
}
.download_containers__6EKg9 .download_inner__XpXGf .download_downloads__fC1kL {
  padding: 55px;
  background: #f9f9f9;
}
.download_containers__6EKg9 .download_inner__XpXGf .download_downloads__fC1kL .download_folder_download__7gIpo:not(:last-child) {
  margin-bottom: 89px;
}
.download_containers__6EKg9 .download_inner__XpXGf .download_downloads__fC1kL .download_folder_download__7gIpo .download_folder_download_title__Cn9Ro {
  margin-bottom: 34px;
}
.download_containers__6EKg9 .download_inner__XpXGf .download_downloads__fC1kL .download_folder_download__7gIpo .download_folder_download_content__wQwJs {
  display: flex;
  flex-wrap: wrap;
  gap: 21px;
}
.download_containers__6EKg9 .download_inner__XpXGf .download_downloads__fC1kL .download_folder_download__7gIpo .download_folder_download_content__wQwJs .download_download_item__dkwZn {
  width: 233px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 21px 13px;
  background: #eee;
  border-radius: 13px;
}
.download_containers__6EKg9 .download_inner__XpXGf .download_downloads__fC1kL .download_folder_download__7gIpo .download_folder_download_content__wQwJs .download_download_item__dkwZn .download_icon_type_download__w09Ht {
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download_containers__6EKg9 .download_inner__XpXGf .download_downloads__fC1kL .download_folder_download__7gIpo .download_folder_download_content__wQwJs .download_download_item__dkwZn .download_icon_type_download__w09Ht svg {
  width: 100%;
}
.download_containers__6EKg9 .download_inner__XpXGf .download_downloads__fC1kL .download_folder_download__7gIpo .download_folder_download_content__wQwJs .download_download_item__dkwZn .download_name_download__c5Zqr {
  width: 100%;
  margin-left: 13px;
  font-size: 0.85em;
  text-overflow: ellipsis;
}
.download_containers__6EKg9 .download_inner__XpXGf .download_downloads__fC1kL .download_folder_download__7gIpo .download_folder_download_content__wQwJs .download_download_item__dkwZn .download_date_download__9XyRd {
  font-size: 0.7em;
}`, "",{"version":3,"sources":["webpack://./src/pages/downloads/download.module.scss"],"names":[],"mappings":"AACI;EACE,YAAA;AAAN;AACM;EACE,YAAA;EACA,+BAAA;AACR;AACM;EACE,aAAA;EACA,mBAAA;AACR;AACU;EACE,mBAAA;AACZ;AACU;EACE,mBAAA;AACZ;AACU;EACE,aAAA;EACA,eAAA;EACA,SAAA;AACZ;AAAY;EACE,YAAA;EACA,aAAA;EACA,qBAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEd;AADc;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAGhB;AAFgB;EACE,WAAA;AAIlB;AADc;EACE,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,uBAAA;AAGhB;AADc;EACE,gBAAA;AAGhB","sourcesContent":[".containers {\n    .inner {\n      height: 100%;\n      > * {\n        height: 100%;\n        min-height: calc(100vh - 110px);\n      }\n      .downloads {\n        padding: 55px;\n        background: #f9f9f9;\n        .folder_download {\n          &:not(:last-child) {\n            margin-bottom: 89px;\n          }\n          .folder_download_title {\n            margin-bottom: 34px;\n          }\n          .folder_download_content {\n            display: flex;\n            flex-wrap: wrap;\n            gap: 21px;\n            .download_item {\n              width: 233px;\n              display: flex;\n              align-items: flex-end;\n              justify-content: center;\n              padding: 21px 13px;\n              background: #eee;\n              border-radius: 13px;\n              .icon_type_download {\n                width: 34px;\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                svg {\n                  width: 100%;\n                }\n              }\n              .name_download {\n                width: 100%;\n                margin-left: 13px;\n                font-size: 0.85em;\n                text-overflow: ellipsis;\n              }\n              .date_download {\n                font-size: 0.7em;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containers": `download_containers__6EKg9`,
	"inner": `download_inner__XpXGf`,
	"downloads": `download_downloads__fC1kL`,
	"folder_download": `download_folder_download__7gIpo`,
	"folder_download_title": `download_folder_download_title__Cn9Ro`,
	"folder_download_content": `download_folder_download_content__wQwJs`,
	"download_item": `download_download_item__dkwZn`,
	"icon_type_download": `download_icon_type_download__w09Ht`,
	"name_download": `download_name_download__c5Zqr`,
	"date_download": `download_date_download__9XyRd`
};
export default ___CSS_LOADER_EXPORT___;
