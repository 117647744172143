import React, { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom'
//hocs
import { useAuth } from "../../hoc/UseAuth";
//libs
import { QUERY_BRANDSLUG } from "../../lib/Queries";
//utils
import { SBrand } from "../../utils/svgs";
//styles
import l from "./signin.module.scss";

export const Signin: React.FC<{}> = () => {
    const [error, setError] = useState(""); // Estado para armazenar mensagens de erro
    const userName = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const brand = QUERY_BRANDSLUG();

    const auth = useAuth();
    async function login() {
        try {
            const res = await auth.authenticate(userName.current?.value as string, password.current?.value as string);
            if (res === "success") {
                // Redirecionar para a home
                navigate("/", { replace: true });
                console.log("Login realizado com sucesso");
            } else {
                setError("Usuário ou senha inválidos");
            }
        } catch (err) { }
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            login();
        }
    }

    return (
        <section className={l.login}>
            <div className={l.acesso}>
                <div className={l.brands}>
                    <div className={l.brand}>
                        <SBrand />
                    </div>
                    <div className={l.brand_universe}>
                        <div className={l.name}>
                            <h1>Brand Universe</h1>
                        </div>
                    </div>
                </div>
                <div className={l.form_content}>
                    <form>
                        <div className={l.imagemsvg}></div>
                        <div className={l.field}>
                            <div className={l.name_input}>
                                <label htmlFor='userName'>
                                    User / E-mail
                                </label>
                            </div>
                            <input
                                type='text'
                                className={`${l.userName} ${!error ? "" : l.error}`}
                                id='userName'
                                name='userName'
                                ref={userName}

                            />
                        </div>
                        <div className={l.field}>
                            <div className={l.name_input}>
                                <label htmlFor='password'>Senha</label>
                            </div>
                            <input
                                type='password'
                                className={`${l.password} ${!error ? "" : l.error}`}
                                id='password'
                                name='password'
                                ref={password}
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                        <div className={l.buttons} onClick={() => login()}>
                            <div className={l.button}>
                                Entrar
                            </div>
                            <div className={`${l.field} ${l.error}`}>
                                <p className={l.error_mensage}>{error}</p>
                            </div>
                            <div className={l.reset}>
                                {/* <a className={l.simple_button' onClick={forgotPassword}>
                  Esqueceu sua senha?
                </a> */}
                            </div>
                        </div>
                    </form>
                    <p className={l.footer_login}>
                        © Enredo 2023 - Brand Innovation
                    </p>
                </div>
            </div>
            <div className={l.image}>
                <figure>
                    {brand && <img
                        src={brand.background.url}
                        alt='' />
                    }
                </figure>
            </div>
        </section>
    );
}