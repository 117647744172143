import brand from "../data/brand.json";

export const sections = () => {
  const sections: any = {};
  
  for (const section of brand.sections) {
    sections[section.name] = section;
    for (const subSection of section.sub_sections) {
      sections[subSection.name] = subSection;
    }
  }

  return sections;
};
