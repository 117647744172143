import {Link} from 'react-router-dom'
//styles
import m from "./menu.module.scss";

export const MenuSubItem = ({ open, sections, }: any) => {
    return (
        <>
            <div className={m.sub_item} style={{
                height: open ? 'fit-content' : 0,
                position: open ? 'relative' : 'absolute',
                zIndex: open ? 1 : -1,
                opacity: open ? 1 : 0
            }}>
                {Object.values(sections)?.map((section: any, index: number) => {
                    return (
                        <div key={index} className={m.sub_item_name}>
                            <Link to={`/section/${section.slug}/${section.modules && section.modules[0]?.module}`} ><p>{section.name}</p></Link>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
