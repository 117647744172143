// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.module_box {
  height: 100%;
  min-height: calc(100vh - 110px);
  padding: 55px;
}`, "",{"version":3,"sources":["webpack://./src/pages/modules/modules.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,+BAAA;EACA,aCgBe;ADjBjB","sourcesContent":["@import \"src/styles/variables\";\n\n.module_box {\n  height: 100%;\n  min-height: calc(100vh - 110px);\n  padding: $bu-space-box-4;\n}\n","$cinza-e-1: #37474f;\n$cinza-e-2: #78909c;\n$cinza-e-3: #90a4ae;\n$cinza-c-1: #cfd8dc;\n$cinza-c-2: #eceff1;\n$roxo: #9e0eff;\n$roxo-a: #a671ff;\n$rosa: #ce08eb;\n$azul: #0c1e8c;\n$turquesa: #00bfb3;\n\n$bu-gray: #e6e6e6;\n$border-grid-width: calc(100vw * 0.0625);\n$border-grid-height: calc(100vh * 0.0833);\n$border-grid-width-fina: calc(100vh * 0.062);\n$border-grid-height-fina: calc(100vh * 0.056);\n$column-grid-width: calc(100vw / 8);\n$column-grid-height: calc(100vh / 6);\n$bu-space-box-1: 13px;\n$bu-space-box-2: 21px;\n$bu-space-box-3: 34px;\n$bu-space-box-4: 55px;\n$bu-space-top: 110px;\n\n// @if (orientation: portrait) {\n//   $border-grid-width: calc($border-grid-width / 2);\n//   $border-grid-height: calc($border-grid-height / 2);\n//   $border-grid-width-fina: calc($border-grid-width-fina / 2);\n//   $border-grid-height-fina: calc($border-grid-height-fina / 2);\n//   $column-grid-width: calc($column-grid-width / 2);\n//   $column-grid-height: calc($column-grid-height / 2);\n//   $bu-space-box-1: 8px;\n//   $bu-space-box-2: 13px;\n//   $bu-space-box-3: 21px;\n//   $bu-space-box-4: 34px;\n//   $bu-space-top: 55px;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
