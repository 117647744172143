import { useParams } from 'react-router-dom'
//components
import { Left } from '../../components/Structure/Left'
import { Right } from '../../components/Structure/Right'
//utils
import { SBrand } from '../../utils/svgs'
import { sections } from '../../utils/sections'
//data
import brand from '../../data/brand.json'
//styles
import m from "./modules.module.scss";
import "./modules.scss";

export const Modules: React.FC<{}> = ({ }) => {
    const { section, module } = useParams();

    const Item: any = Object.values(sections())?.find((i: any) => i.slug === section);
    const foundModule = Item?.modules?.find((m: any) => m.module === module);

    return (
        <>
            <Left
                urlLogoTop={'/'}
                logoTop={<SBrand color={brand.color['day-blue']} />}
                logoHeight={'34px'}
                title={Item?.name}
                sub_title={Item.name === foundModule?.name ? '' : foundModule?.name}
                inside={foundModule?.description}
                classStyle={'left_section'}
                modules={Item?.modules}
                downloads={Item?.downloads}
            />
            <Right
                top={true}
                logoHeight={'34px'}
                borderRadius={'34px'}
                classStyle={'right_section'}
            >
                <div className={m.columns}>
                    <div className={m.containers}>
                        {foundModule &&
                            <div
                                className={m.inner}
                                dangerouslySetInnerHTML={{ __html: foundModule?.html }}
                            ></div>
                        }
                    </div>
                </div>
            </Right>
        </>
    )
}