//components
import { Left } from '../components/Structure/Left'
import { Right } from '../components/Structure/Right'
import { CardsHome } from '../components/CardsHome'
//utils
import { SLogo } from '../utils/svgs'
import { sections } from '../utils/sections';
//data
import brand from "../data/brand.json";
//styles
import "./home.scss";
import { Link } from 'react-router-dom';

export const Home = () => {

    const selectedSections = [
        'logo', 'paleta_cromatica', 'tipografia', 'atributos_de_personalidade', 'identidade_verbal'
    ]

    const secs = Object.values(sections())

    console.log(secs)

    return (
        <>
            <Left
                urlLogoTop={'/'}
                logoTop={<SLogo color={brand.color['day-blue']} />}
                logoHeight={'34px'}
                title={'Nossa identidade é a maior expressão da nossa marca.'}
                inside={'<p>No universo de marca Turia, você encontra tudo que você precisa saber sobre nossa marca. Utilize-a como um guia para criar uma comunicação forte e consistente.</p>'}
                classStyle={'left_home'}
            />
            <Right
                background={brand.color["day-blue"]}
                classStyle={'right_home'}
            >
                <div className="containers">
                    <div className='items'>
                        {secs
                            ?.filter((s: any) => selectedSections.includes(s.slug))
                            .map((s: any, i: number) => (
                                <div className='item'>
                                    <Link to={`/section/${s.slug}/${s.modules[0]?.module}`}>
                                        <img src={s?.images.thumbnail} alt="" />
                                        <p className='nome'>{s.name} </p>
                                    </Link>
                                </div>
                            ))}
                        <div className='item'>
                            <Link to={`/downloads`}>
                                <img src='https://branduniverse.blob.core.windows.net/brand-universe/bu_turia_page_download.jpg' alt="" />
                                <p className='nome'>Downloads </p>
                            </Link>
                        </div>
                        {/* <CardsHome /> */}
                    </div>
                </div>
            </Right>
        </>
    )
}