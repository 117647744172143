// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containers .items {
  flex: 1 1;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.containers .items .item {
  width: 33.3333333333%;
  height: 50%;
}
.containers .items .item a {
  display: block;
  height: 100%;
  position: relative;
  color: #fff;
  text-transform: uppercase;
}
.containers .items .item a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.containers .items .item a p {
  width: 100%;
  padding: 21px;
  position: absolute;
  bottom: 0;
  text-align: center;
  font-size: 18px;
}

@media (orientation: portrait) {
  .containers .items {
    height: 100%;
  }
  .containers .items .item {
    width: 50%;
    aspect-ratio: 4/3;
    min-height: auto;
  }
  .containers .items .item a p {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home.scss"],"names":[],"mappings":"AACE;EACE,SAAA;EACA,aAAA;EACA,aAAA;EACA,eAAA;EACA,oBAAA;AAAJ;AACI;EACE,qBAAA;EACA,WAAA;AACN;AAAM;EACE,cAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,yBAAA;AAER;AADQ;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAGV;AADQ;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,SAAA;EACA,kBAAA;EACA,eAAA;AAGV;;AAIA;EAEI;IACE,YAAA;EAFJ;EAGI;IACE,UAAA;IACA,iBAAA;IACA,gBAAA;EADN;EAGQ;IACE,eAAA;EADV;AACF","sourcesContent":[".containers {\n  .items {\n    flex: 1;\n    height: 100vh;\n    display: flex;\n    flex-wrap: wrap;\n    align-items: stretch;\n    .item {\n      width: calc(100% / 3);\n      height: calc(100% / 2);\n      a {\n        display: block;\n        height: 100%;\n        position: relative;\n        color: #fff;\n        text-transform: uppercase;\n        img {\n          width: 100%;\n          height: 100%;\n          object-fit: cover;\n        }\n        p {\n          width: 100%;\n          padding: 21px;\n          position: absolute;\n          bottom: 0;\n          text-align: center;\n          font-size: 18px;\n        }\n      }\n    }\n  }\n}\n\n@media (orientation: portrait) {\n  .containers {\n    .items {\n      height: 100%;\n      .item {\n        width: 50%;\n        aspect-ratio: 4/ 3;\n        min-height: auto;\n        a {\n          p {\n            font-size: 12px;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
