'use client'
import {Link} from 'react-router-dom'
import { useParams } from 'react-router-dom'
//components
import { Menu } from '../../components/Menu/index'
//utils
import { useScrollPosition } from '../../utils/scroll'
import { sections } from '../../utils/sections'
import { ICON_Downloads, SLogo } from '../../utils/svgs'

import s from "./structure.module.scss";

export const Left = ({
    urlLogoTop,
    logoTop,
    logoHeight,
    title,
    sub_title,
    inside,
    classStyle,
    modules,
    downloads
}: any) => {
    const { section, module } = useParams()

    const Item: any = Object.values(sections())?.find((item: any) => item.slug === section);
    const Module = Item && Item.modules ? Item.modules.find((item: any) => item.module === module) : null;

    const scrollY = useScrollPosition();

    return (
        <div className={`${s.column_bu} ${s.left_bu} ${s[classStyle]}`}>
            <div className={s.top_left}>
                <div className={s.bu_logo} style={logoHeight && { height: logoHeight }}>
                    {logoTop ?
                        <Link to={urlLogoTop ? urlLogoTop : ''}>
                            {logoTop}
                        </Link> :
                        <Link to={urlLogoTop ? urlLogoTop : ''}>
                            <SLogo />
                        </Link>
                    }
                </div>
            </div>
            <div className={s.bu_left_content}>
                <div className={s.informationPage}
                    style={{
                        minHeight: scrollY < 110 ? `calc(100vh - (110px - ${scrollY}px))` : '100vh',
                    }}
                >
                    <div className={s.informations}>
                        {title ?
                            <h2 className={s.title} style={{

                            }}>{title}</h2> :
                            ''
                        }
                        {sub_title ?
                            <h3 className={s.sub_title}> {sub_title}</h3> :
                            ''
                        }
                        {inside ? (
                            <div className={s.inside} dangerouslySetInnerHTML={{
                                __html: inside ?
                                    inside :
                                    inside
                            }} />) :
                            ''
                        }
                    </div>
                    {/* <div>
                        <button onClick={generatePdf}>
                            Generate PDF
                        </button>
                        <a download="json_data.pdf">Download PDF</a>
                    </div> */}
                    <div>
                        <div className={s.modules}>
                            {modules && modules.length > 1 ? modules?.map((module: any, index: number) => {
                                return <div className={s.module} key={index}>
                                    <Link to={"/section/" + section + "/" + module.module}>
                                        <b>{module.name}</b>
                                    </Link>
                                </div>
                            })
                                : ''}
                        </div>
                        <div className={s.downloads}>
                            {downloads ? downloads?.map((download: any, index: number) => {
                                return <div className={s.download} key={index}>
                                    <ICON_Downloads color="#253fff" />
                                    <Link to={download.file} target='_blank' rel="noreferrer">{download.name}</Link>
                                    </div>
                            })
                                : ''}
                        </div>
                    </div>
                </div>
                <Menu />
            </div>
        </div >
    )
}
