// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.globals_container_bu__k4Qsz .globals_columns__SJwRn {
  display: flex;
}

@media (orientation: portrait) {
  .globals_container_bu__k4Qsz .globals_columns__SJwRn {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/globals.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;;AAIA;EAEI;IACE,sBAAA;EAFJ;AACF","sourcesContent":[".container_bu {\n  .columns {\n    display: flex;\n  }\n}\n\n@media (orientation: portrait) {\n  .container_bu {\n    .columns {\n      flex-direction: column;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_bu": `globals_container_bu__k4Qsz`,
	"columns": `globals_columns__SJwRn`
};
export default ___CSS_LOADER_EXPORT___;
