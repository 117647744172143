import brand from "../data/brand.json";

export const modules = () => {
  const modules: any = {};

  for (const section of brand.sections) {
    for (const module of section.modules) {
      modules[module.name] = { section: section.slug, ...module };
      for (const subSection of section.sub_sections) {
        for (const module of subSection.modules) {
          modules[module.name] = { section: subSection.slug, ...module };
        }
      }
    }
  }

  return modules;
};
