// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modules_containers__K2TLw {
  height: 100%;
}
.modules_containers__K2TLw *:target {
  scroll-margin-top: 110px/2;
}
.modules_containers__K2TLw .modules_inner__znwmH {
  height: 100%;
}
.modules_containers__K2TLw .modules_inner__znwmH .modules_module_box__agB0Y {
  height: 100%;
  min-height: calc(100vh - 110px);
}`, "",{"version":3,"sources":["webpack://./src/pages/modules/modules.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAEE;EACE,0BAAA;AAAJ;AAEE;EACE,YAAA;AAAJ;AACI;EACE,YAAA;EACA,+BAAA;AACN","sourcesContent":["@import \"src/styles/variables\";\n\n.containers {\n  height: 100%;\n  *:target {\n    scroll-margin-top: calc($bu-space-top) / 2;\n  }\n  .inner {\n    height: 100%;\n    .module_box {\n      height: 100%;\n      min-height: calc(100vh - 110px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containers": `modules_containers__K2TLw`,
	"inner": `modules_inner__znwmH`,
	"module_box": `modules_module_box__agB0Y`
};
export default ___CSS_LOADER_EXPORT___;
