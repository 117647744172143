import { Link } from "react-router-dom";
//components
import { Left } from "../../components/Structure/Left"
import { Right } from "../../components/Structure/Right"
//utils
import { ICON_Downloads, SBrand } from "../../utils/svgs"
//data
import brand from "../../data/brand.json"
//styles
import d from "./download.module.scss";

export const Downloads = () => {
    const sections = [...brand?.sections]

    const downloads: any = {}
    sections.forEach(section => {
        downloads[section.name] = [...section.downloads]
        section.sub_sections.forEach(sub_section => {
            downloads[section.name].push(...sub_section.downloads)
        })
    })

    return (
        <>
            <Left
                urlLogoTop={'/'}
                logoTop={<SBrand color={brand.color['day-blue']} />}
                logoHeight={'34px'}
                title={'Downloads'}
                inside={'<p>Aqui você encontra um atalho para todos os itens passíveis de download.</p>'}
                classStyle={'left_section'}
            />
            <Right
                top={true}
                logoHeight={'34px'}
                borderRadius={'34px'}
                classStyle={'right_section'}
            >
                <div className={d.containers}>
                    <div className={d.inner}>
                        <div className={d.downloads}>
                            {brand?.sections.map((section: any, i: number) => {
                                if (downloads[section.name].length > 0) {
                                    return (
                                        <div className={d.folder_download} key={i}>
                                            <div className={d.folder_download_title}>
                                                <h2>{section.name}</h2>
                                            </div>
                                            <div className={d.folder_download_content}>
                                                {downloads[section.name].map((download: any, j: number) => (
                                                    <Link to={download.file} key={j} target="_blank">
                                                        <div className={d.download_item}>
                                                            <div className={d.icon_type_download}>
                                                                <ICON_Downloads color={brand.color["day-blue"]} />
                                                            </div>
                                                            <p className={d.name_download}>{download.name}</p>
                                                            {/*Data que o arquivo foi upado 
                                                            
                                                            <p className={d.date_download}>
                                                                {`${new Date(1691696953518).toLocaleDateString()} -
                                ${new Date(1691696953518).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
                                                            </p> */}
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                }
                                return null;  // return null if no downloads for this section
                            })}

                        </div>
                    </div>
                </div>
            </Right>
        </>
    )
}